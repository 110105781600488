import React from 'react';
import { GroupItem } from './context';
import {StyledGroupToggle, StyledGroupToggleButtons, StyledGroupToggleHeader, StyledHighlightedSpan} from './styles';
import { GroupToggleArrowIcon } from '../../../../../../icons/ui/GroupToggleArrow';
import { Heading4 } from '../../../../../../libs/uiKit/globalStyledComponents';
import Button from '../../../../../../libs/uiKit/button';
import { TrashIcon } from '../../../../../../icons/ui/Trash';
import { PencilIcon } from '../../../../../../icons/ui/Pencil';
import { AddIcon } from '../../../../../../icons/ui/Add';
import GroupToggleBody from './GroupToggleBody';
import { useRolesContext } from '../../../ui/context';
import {useGlobalContext} from "../../../../../../apps/main/ui/GlobalContext";
import {Highlighted} from "./Highlighted";

const GroupToggle: React.FC<{ group: GroupItem, isFiltered: boolean, searchWord: string }> = ({ group, isFiltered, searchWord }) => {
	const { openDeleteGroupHandler, openCreateUserHandler, openCreateGroupHandler } = useRolesContext();
	const [stateToggleOpen, setToggleOpen] = React.useState<boolean>(false);

	const {
		user: { isSuper, isAdmin },
	} = useGlobalContext();



	const makeTitleGray = searchWord && !group.name?.toLowerCase().includes(searchWord);

	return (
		<>
			<StyledGroupToggle>
				<StyledGroupToggleHeader onClick={(): void => setToggleOpen(state => !state)}>
					<GroupToggleArrowIcon direction={(stateToggleOpen || isFiltered) ? 'down' : 'right'} />
					{!makeTitleGray && <Heading4><Highlighted highlight={searchWord} text={group.name}/></Heading4>}
					{makeTitleGray && <Heading4 style={{color: "gray"}}>{group.name}</Heading4>}
				</StyledGroupToggleHeader>

				{!group.specialType && (
					<StyledGroupToggleButtons>
						{isSuper &&
							<Button tooltip='Удалить группу' onClick={(): void => openDeleteGroupHandler(group.id)} appearance='icon' icon={<TrashIcon />} />
						}
						{isSuper &&
							<Button tooltip='Редактировать' onClick={(): void => openCreateGroupHandler({ ...group, isEdit: true })} appearance='icon' icon={<PencilIcon />} />
						}
						{(isSuper || isAdmin) &&
							<Button tooltip='Добавить пользователя' onClick={(): void => openCreateUserHandler({ groupId: group.id || undefined })} appearance='icon' icon={<AddIcon />} />
						}
					</StyledGroupToggleButtons>
				)}
			</StyledGroupToggle>
			{(stateToggleOpen || isFiltered) && <GroupToggleBody currentGroup={group} isFiltered={isFiltered} searchWord={searchWord} />}
		</>
	);
};

export default React.memo(GroupToggle);
