import {StyledHighlightedSpan} from "./styles";
import React from "react";

export const Highlighted = ({text = '', highlight = ''}) => {
    if (!highlight.trim()) {
        return <span>{text}</span>
    }
    const escapedSearchString = highlight.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    const regex = new RegExp(`(${escapedSearchString})`, 'gi');
    const parts = text.split(regex)
    console.log(parts);
    return (
        <span>
        {parts.filter(part => part).map((part, i) => (
            regex.test(part) ? <StyledHighlightedSpan key={i}>{part}</StyledHighlightedSpan> : <span key={i}>{part}</span>
        ))}
    </span>
    )
}