import {AgreementId, CreateAgreementForm} from '../../ui/context';
import {RADIO_BOOLEAN, RADIO_SEX} from '../../../../../libs/utils/staticData';
import {
	AddAgreementFilesMutation,
	AddAgreementFilesMutationVariables,
	AddressFragment,
	AgreementFileContextEnum,
	CalcFormInput,
	ContragentFillingTypeEnum,
	ContragentForm,
	ContragentFragment,
	LoadAgreementQuery,
	PropertyObjectForm,
	SaveAgreementMutation,
	SaveAgreementMutationVariables,
	SaveAnketaMutation,
	SaveAnketaMutationVariables,
	TitleObjectForm,
	UpdateContragentFillingMutation,
	UpdateContragentFillingMutationVariables,
} from '../../../../../libs/api/graphqlTypes';
import {ContragentField, ContragentId} from '../../widgets/contragent/ui/context';
import {IDaDataValue} from '../../../../../libs/uiKit/daData/types';
import {PropertyField} from '../../widgets/property/ui/context';
import {TitleField} from '../../widgets/title/ui/context';
import {formatDateToStringRu, formatStringRuToDate} from '../../../../../libs/utils/gormatDateToUsa';
import {ISelectItem} from '../../../../../libs/uiKit/select/types';
import {TypeFormHook} from '../../../../../libs/uiKit/fieldsBuilder/types';
import {QueriesCreateAgreement} from './queries';
import {GQLApi} from '../../../../../libs/api/gqlApi';
import {
	ADD_AGREEMENT_FILE,
	SAVE_AGREEMENT_DRAFT,
	SAVE_ANKETA,
	UPDATE_CONTRAGENT_FILLING
} from '../../../../../libs/api/commands';
import {ErrorType} from '../../../../../libs/utils/getGraphqlError';
import {FillingType} from '../../widgets/anketaFilling/ui/context';
import {OnlineFillingForm} from '../../widgets/anketaFilling/widgets/onlineFilling/ui/context';
import {AGREEMENT_DRAFT, LOAD_CONTRACT} from '../../../../../libs/api/queries';
import {getClearSelectValue} from '../../../../../libs/utils/getSelectdefaultValue';
import {UploadFillingForm} from "../../widgets/anketaFilling/widgets/uploadFilling";

export type AgreementSteps = 'contragentStep' | 'jobStep' | 'bankStep' | 'propertyStep' | 'titleStep' | 'insuranceStep' | 'additionalFilesStep';

type FormatTitleCmd = {
	title: TitleObjectForm;
	address?: IDaDataValue | null;
	addressEgrn?: string;
	cadastralNumber?: string;
};

export const DEFAULT_CONTRAGENT_DATA: ContragentField = {
	addressRadio: RADIO_BOOLEAN[0].value,
	gender: RADIO_SEX[0].value,
};

export const DEFAULT_PROPERTY_DATA: PropertyField = {
	objectOfUnfinishedConstruction: RADIO_BOOLEAN[1].value,
	basementOrAttic: RADIO_BOOLEAN[1].value,
	interiorDecoration: RADIO_BOOLEAN[1].value,
	rent: RADIO_BOOLEAN[1].value,
	damage: RADIO_BOOLEAN[1].value,
	repairPlanning: RADIO_BOOLEAN[1].value,
	replanPlanning: RADIO_BOOLEAN[1].value,
	signaling: RADIO_BOOLEAN[1].value,
	openFire: RADIO_BOOLEAN[1].value,
	additionalRisks: RADIO_BOOLEAN[1].value,
	pledgeShare: 100,
};
export const DEFAULT_TITLE_DATA: TitleField = {
	litigation: RADIO_BOOLEAN[1].value,
	unregisterPersons: RADIO_BOOLEAN[1].value,
	minors: RADIO_BOOLEAN[1].value,
	subjectEncumbrances: RADIO_BOOLEAN[1].value,
	share: 100,
};

const formatAddressToAddressForm = (address: AddressFragment | undefined | null): IDaDataValue | null =>
	address && address.value
		? {
				value: address.value || '',
				unrestricted_value: address.unrestricted_value || undefined,
				data: address.data,
		  }
		: null;

const formatPhoneForForm = (phone: string | undefined | null) : string | undefined => {
	console.log('formatPhoneForForm', phone);
	if(phone && phone.length === 11 && phone.at(0) === '7'){
		return `+${phone}`;
	}
	return phone || undefined;
}

const formatFillingTypeToForm = (fillingType: ContragentFillingTypeEnum | undefined | null): FillingType => {
	switch (fillingType) {
		case ContragentFillingTypeEnum.Send:
			return 'send';
		case ContragentFillingTypeEnum.Upload:
			return 'upload';
		case ContragentFillingTypeEnum.Online:
			return 'online';
		case ContragentFillingTypeEnum.Declaration:
			return 'declaration';
		default:
			return null;
	}
};
const formatFillingTypeToBack = (fillingType: FillingType | undefined | null): ContragentFillingTypeEnum | null => {
	switch (fillingType) {
		case 'send':
			return ContragentFillingTypeEnum.Send;
		case 'upload':
			return ContragentFillingTypeEnum.Upload;
		case 'online':
			return ContragentFillingTypeEnum.Online;
		case 'declaration':
			return ContragentFillingTypeEnum.Declaration;
		default:
			return null;
	}
};

export const formatPersonDataToDataForm = (person: ContragentFragment): ContragentField => ({
	fullName: {
		value: person.fio || '',
	},
	birthDate: person.birthday ? formatStringRuToDate(person.birthday) : null,
	addressRadio: (person.registrationAddressIsActual === null || person.registrationAddressIsActual === true) ? RADIO_BOOLEAN[0].value : RADIO_BOOLEAN[1].value,
	phoneMobile: formatPhoneForForm(person.phone),
	email: person.email || undefined,
	gender: RADIO_SEX.find(sex => sex.value === person.sex)?.value || undefined,
	share: Number(person.share) || 0,
	addr1: formatAddressToAddressForm(person.registrationAddress),
	addr2: formatAddressToAddressForm(person.actualAddress),
	authDocSer: person.passportSeries || undefined,
	authDocNumber: person.passportNumber || undefined,
	authDocDate: person.passportIssueDate ? formatStringRuToDate(person.passportIssueDate) : null,
	authDocIssuerCode: person.passportDevisionCode || undefined,
	authDocIssuer: person.passportIssuedBy || undefined,
	country: getClearSelectValue(person.passportCountry),
	fillingType: formatFillingTypeToForm(person.fillingType),
	isn: person.isn || undefined,
	anketaIsn: person.anketaIsn || undefined,
	// job
	jobPlace: getClearSelectValue(person.employment?.jobPlace),
	organizationName: formatAddressToAddressForm(person.employment?.organizationName),
	organizationAddress: formatAddressToAddressForm(person.employment?.jobAddress),
	isOfficeJob: typeof person.employment?.isOfficeWorker === 'boolean' ? (person.employment?.isOfficeWorker ? RADIO_BOOLEAN[0].value : RADIO_BOOLEAN[1].value) : undefined,
	isSoldier: typeof person.employment?.isSoldier === 'boolean' ? (person.employment?.isSoldier ? RADIO_BOOLEAN[0].value : RADIO_BOOLEAN[1].value) : undefined,
	position: person.employment?.position || undefined,
	field: getClearSelectValue(person.employment?.organizationActivity),
	riskGroup: getClearSelectValue(person.employment?.riskGroup),
	profession: getClearSelectValue(person.employment?.profession),
	anketaFileId: person.anketaFileId || undefined,
	anketaSigned: !!person.anketaSigned,
});
const formatFormPersonDataToBackPerson = (person: ContragentField): ContragentFragment => ({
	fio: person.fullName?.value || null,
	birthday: person.birthDate ? formatDateToStringRu(person.birthDate) : null,
	registrationAddressIsActual: person.addressRadio === 'true',
	phone: person.phoneMobile || null,
	email: person.email ? person.email.trim() : null,
	sex: person.gender || null,
	share: Number(person.share) || 0,
	registrationAddress: person?.addr1 || null,
	actualAddress: person.addr2 || null,
	passportSeries: person.authDocSer || null,
	passportNumber: person.authDocNumber || null,
	passportIssueDate: person.authDocDate ? formatDateToStringRu(person.authDocDate) : null,
	passportDevisionCode: person.authDocIssuerCode || null,
	passportIssuedBy: person.authDocIssuer || null,
	passportCountry: getClearSelectValue(person.country),
	fillingType: formatFillingTypeToBack(person.fillingType),
	isn: person.isn || undefined,
	anketaIsn: person.anketaIsn || undefined,
	anketaFileId: person.anketaFileId,
	anketaSigned: !!person.anketaSigned,
	employment: {
		jobPlace: getClearSelectValue(person.jobPlace),
		organizationName: person.organizationName || null,
		jobAddress: person.organizationAddress || null,
		isOfficeWorker: typeof person.isOfficeJob === 'string' ? person.isOfficeJob === 'true' : undefined,
		isSoldier: typeof person.isSoldier === 'string' ? person.isSoldier === 'true' : undefined,
		position: person.position || null,
		organizationActivity: getClearSelectValue(person.field),
		riskGroup: getClearSelectValue(person.riskGroup),
		profession: getClearSelectValue(person.profession),
	},
});

const formatTitleDataToDataForm = ({ title, address, addressEgrn, cadastralNumber }: FormatTitleCmd): TitleField => ({
	address: title.titleAddress ? formatAddressToAddressForm(title.titleAddress) : address,
	share: Number(title.titleShare) || 0,
	subjectEncumbrances: title.objectEncumbrances || undefined,
	minors: title.minors ? RADIO_BOOLEAN[0].value : RADIO_BOOLEAN[1].value,
	unregisterPersons: title.unregisterPersons ? RADIO_BOOLEAN[0].value : RADIO_BOOLEAN[1].value,
	litigation: title.litigation ? RADIO_BOOLEAN[0].value : RADIO_BOOLEAN[1].value,
	addressEgrn: title.addressEgrn ? title.addressEgrn : addressEgrn || undefined,
	typeEncumbrances: title.typeEncumbrances || undefined,
	cadastralNumber: title.titleCadastralNumber ? title.titleCadastralNumber : cadastralNumber || undefined,
});
const formatFormTitleDataToBackTitle = (title: TitleField): TitleObjectForm => ({
	litigation: title.litigation === 'true',
	minors: title.minors === 'true',
	objectEncumbrances: title.subjectEncumbrances,
	titleAddress: title.address,
	titleShare: Number(title.share) || undefined,
	unregisterPersons: title.unregisterPersons === 'true',
	typeEncumbrances: title.typeEncumbrances,
	addressEgrn: title.addressEgrn,
	titleCadastralNumber: title.cadastralNumber || undefined,
});

const formatPropertyDataToDataForm = (property: PropertyObjectForm): PropertyField => ({
	pledge: getClearSelectValue(property.propertyPledge),
	pledgeAddress: formatAddressToAddressForm(property.propertyPledgeAddress),
	pledgeAddressEgrn: property.pledgeAddressEgrn || undefined,
	pledgeShare: Number(property.pledgeShare) || 0,
	agency: property.agency || undefined,
	subjectCost: Number(property.objectCost) || undefined,
	nameOfAppraiser: property.nameOfAppraiser || undefined,
	subjectCostByContract: Number(property.objectCostByContract) || undefined,
	area: Number(property.area) || undefined,
	roomsCount: Number(property.roomsCount) || undefined,
	objectOfUnfinishedConstruction: property.objectOfUnfinishedConstruction ? RADIO_BOOLEAN[0].value : RADIO_BOOLEAN[1].value,
	repairPlanning: property.repairPlanning ? RADIO_BOOLEAN[0].value : RADIO_BOOLEAN[1].value,
	repairIntervalStart: property.repairIntervalFrom ? formatStringRuToDate(property.repairIntervalFrom) : null,
	repairIntervalEnd: property.repairIntervalTo ? formatStringRuToDate(property.repairIntervalTo) : null,
	riskCoefficient: Number(property.riskCoefficient) || 0,
	basementOrAttic: property.basementOrAttic ? RADIO_BOOLEAN[0].value : RADIO_BOOLEAN[1].value,
	floor: Number(property.floor) || undefined,
	floorCount: Number(property.floorCount) || undefined,
	yearOfBuild: property.buildYear ? String(property.buildYear) : undefined,
	yearOfCapitalRepair: property.yearOfCapitalRepair ? String(property.yearOfCapitalRepair) : undefined,
	wallMaterial: getClearSelectValue(property.wallMaterial),
	floorMaterial: getClearSelectValue(property.floorMaterial),
	interiorDecoration: property.interiorDecoration ? RADIO_BOOLEAN[0].value : RADIO_BOOLEAN[1].value,
	replanPlanning: property.repairPlanning ? RADIO_BOOLEAN[0].value : RADIO_BOOLEAN[1].value,
	replanIntervalStart: property.replanIntervalFrom ? formatStringRuToDate(property.replanIntervalFrom) : null,
	replanIntervalEnd: property.replanIntervalTo ? formatStringRuToDate(property.replanIntervalTo) : null,
	rent: property.rent ? RADIO_BOOLEAN[0].value : RADIO_BOOLEAN[1].value,
	damage: property.damage ? RADIO_BOOLEAN[0].value : RADIO_BOOLEAN[1].value,
	signaling: property.signaling ? RADIO_BOOLEAN[0].value : RADIO_BOOLEAN[1].value,
	signalingInput: property.signalingInfo || undefined,
	openFire: property.openFire ? RADIO_BOOLEAN[0].value : RADIO_BOOLEAN[1].value,
	openFireInput: property.openFireInfo || undefined,
	additionalRisks: property.additionalRisks ? RADIO_BOOLEAN[0].value : RADIO_BOOLEAN[1].value,
	additionalRisksInput: property.additionalRisksInfo || undefined,
	cadastralNumber: property.pledgeCadastralNumber || undefined,
});
const formatFormPropertyDataToBackProperty = (property: PropertyField): PropertyObjectForm => ({
	agency: property.agency || null,
	area: property.area || null,
	basementOrAttic: property.basementOrAttic === 'true',
	buildYear: Number(property.yearOfBuild) || null,
	damage: property.damage === 'true',
	floor: property.floor || null,
	floorCount: property.floorCount || null,
	floorMaterial: getClearSelectValue(property.floorMaterial),
	interiorDecoration: property.interiorDecoration === 'true',
	nameOfAppraiser: property.nameOfAppraiser || null,
	objectCost: property.subjectCost || null,
	objectCostByContract: property.subjectCostByContract || null,
	objectOfUnfinishedConstruction: property.objectOfUnfinishedConstruction === 'true',
	pledgeAddressEgrn: property.pledgeAddressEgrn || null,
	pledgeShare: Number(property.pledgeShare) || 0,
	propertyPledge: getClearSelectValue(property.pledge),
	propertyPledgeAddress: property.pledgeAddress || null,
	rent: property.basementOrAttic === 'true',
	repairIntervalFrom: property.repairIntervalStart ? formatDateToStringRu(property.repairIntervalStart) : null,
	repairIntervalTo: property.repairIntervalEnd ? formatDateToStringRu(property.repairIntervalEnd) : null,
	repairPlanning: property.repairPlanning === 'true',
	replanIntervalFrom: property.replanIntervalStart ? formatDateToStringRu(property.replanIntervalStart) : null,
	replanIntervalTo: property.replanIntervalEnd ? formatDateToStringRu(property.replanIntervalEnd) : null,
	replanPlanning: property.replanPlanning === 'true',
	riskCoefficient: property.riskCoefficient || null,
	roomsCount: property.roomsCount || null,
	wallMaterial: getClearSelectValue(property.wallMaterial),
	yearOfCapitalRepair: Number(property.yearOfCapitalRepair) || null,
	signaling: property.signaling === 'true',
	signalingInfo: property.signalingInput || undefined,
	openFire: property.openFire === 'true',
	openFireInfo: property.openFireInput || undefined,
	additionalRisks: property.additionalRisks === 'true',
	additionalRisksInfo: property.additionalRisksInput || undefined,
	pledgeCadastralNumber: property.cadastralNumber || undefined,
});

export const setDataToForm = (data: CreateAgreementForm, formHook: TypeFormHook) => {
	Object.entries(data).forEach(([key, value]) => {
		if (value === null || value === undefined) return;
		formHook.setValue(key, value);
	});
};

export const formatAgreementDataToFormData = (agreementData: LoadAgreementQuery): CreateAgreementForm => {
	const {
		riskProperty,
		riskTitle,
		// contragents step
		// job step
		borrower,
		policyholderIsBorrower,
		policyholder,
		// bank step
		coBorrowers,
		credit,
		// property step
		propertyObjects,
		// title step
		titleObjects,
	} = agreementData.loadAgreement?.calcForm || {};

	const isOnlyLife: boolean = !riskProperty && !riskTitle;

	const contragentFormData: ContragentField | null = policyholder ? formatPersonDataToDataForm(policyholder) : DEFAULT_CONTRAGENT_DATA;
	const borrowerFormData: ContragentField | null = borrower ? formatPersonDataToDataForm(borrower) : null;

	const coBorrowersFormData: ContragentField[] = (coBorrowers || []).reduce<ContragentField[]>((prev, coBorrower) => {
		if (!coBorrower) return prev;
		return [...prev, formatPersonDataToDataForm(coBorrower)];
	}, []);

	const properties = riskProperty
		? propertyObjects?.length
			? propertyObjects.reduce<PropertyField[]>((prev, next) => (next ? [...prev, formatPropertyDataToDataForm(next)] : prev), [])
			: [DEFAULT_PROPERTY_DATA]
		: [];

	const titles: TitleField[] = riskTitle
		? titleObjects?.length
			? titleObjects.reduce<TitleField[]>(
					(prev, next, index) =>
						next
							? [
									...prev,
									formatTitleDataToDataForm({
										title: next,
										address: properties[index]?.pledgeAddress,
										addressEgrn: properties[index]?.pledgeAddressEgrn,
										cadastralNumber: properties[index]?.cadastralNumber,
									}),
							  ]
							: prev,
					[]
			  )
			: [{ ...DEFAULT_TITLE_DATA, address: properties[0]?.pledgeAddress, addressEgrn: properties[0]?.pledgeAddressEgrn, cadastralNumber: properties[0]?.cadastralNumber }]
		: [];

	return {
		// contragents step
		contragents: [contragentFormData, ...(borrowerFormData ? [borrowerFormData] : [])],
		isInsurer: typeof policyholderIsBorrower === 'boolean' ? policyholderIsBorrower : true,
		// job step
		jobPlace: getClearSelectValue(policyholder?.employment?.jobPlace),
		organizationName: formatAddressToAddressForm(policyholder?.employment?.organizationName),
		organizationAddress: formatAddressToAddressForm(policyholder?.employment?.jobAddress),
		isOfficeJob:
			typeof policyholder?.employment?.isOfficeWorker === 'boolean' ? (policyholder?.employment?.isOfficeWorker ? RADIO_BOOLEAN[0].value : RADIO_BOOLEAN[1].value) : undefined,
		isSoldier: typeof policyholder?.employment?.isSoldier === 'boolean' ? (policyholder?.employment?.isSoldier ? RADIO_BOOLEAN[0].value : RADIO_BOOLEAN[1].value) : undefined,
		position: policyholder?.employment?.position || undefined,
		field: getClearSelectValue(policyholder?.employment?.organizationActivity),
		riskGroup: getClearSelectValue(policyholder?.employment?.riskGroup),
		profession: getClearSelectValue(policyholder?.employment?.profession),
		// bank step
		coBorrowers: coBorrowersFormData,
		isHaveCoBorrower: coBorrowersFormData.length ? RADIO_BOOLEAN[0].value : RADIO_BOOLEAN[1].value,
		insureType: String(credit?.insuranceType || 1),
		creditOrganizationName: getClearSelectValue(credit?.bank),
		dealCountry: formatAddressToAddressForm(credit?.city),
		summ: credit?.creditSum || undefined,
		creditTerms: credit?.creditTerms || undefined,
		creditRate: credit?.creditRate || undefined,
		creditIssueDate: credit?.creditIssueDate ? formatStringRuToDate(credit?.creditIssueDate) : null,
		creditAgreementNumber: credit?.creditAgreementNumber || undefined,
		dateBegin: credit?.dateBegin ? formatStringRuToDate(credit?.dateBegin) : null,
		firstPayDate: credit?.firstPayDate ? formatStringRuToDate(credit?.firstPayDate) : null,
		dateExactEnd: credit?.dateExactEnd ? formatStringRuToDate(credit?.dateExactEnd) : null,
		periodTypeChoose: credit?.periodTypeChoose ? credit?.periodTypeChoose : 'month',
		agent: getClearSelectValue(credit?.agent),
		prevSk: getClearSelectValue(credit?.prevSk),
		renewReason: getClearSelectValue(credit?.renewReason),
		renewReasonOther: credit?.renewReasonOther || undefined,
		borrowerShare: isOnlyLife && !Number(credit?.borrowerShare) ? 100 : Number(credit?.borrowerShare),
		// property step
		properties,
		// title step
		titles,
		birthDateMethod: getClearSelectValue(credit?.birthDateMethod),
		marketing: getClearSelectValue(credit?.marketing)
	};
};

const formatFormDataToAgreementData = (formData: CreateAgreementForm, step: AgreementSteps): CalcFormInput => {
	const {
		// contragents step
		contragents,
		isInsurer,
		// job step
		jobPlace,
		organizationName,
		organizationAddress,
		isOfficeJob,
		isSoldier,
		position,
		field,
		// bank step
		dealCountry,
		insureType,
		creditOrganizationName,
		summ,
		creditTerms,
		creditRate,
		creditIssueDate,
		creditAgreementNumber,
		dateBegin,
		firstPayDate,
		agent,
		prevSk,
		renewReason,
		renewReasonOther,
		borrowerShare,
		coBorrowers,
		// property step
		properties,
		// titles step
		titles,
		riskGroup,
		profession,
		birthDateMethod,
		marketing,
		periodTypeChoose,
		dateExactEnd
	} = formData;

	return {
		stage: step,
		// contragents step
		policyholder: contragents[0]
			? formatFormPersonDataToBackPerson({ ...contragents[0], share: borrowerShare, jobPlace, organizationName, organizationAddress, isOfficeJob, isSoldier, position, field, riskGroup, profession })
			: null,
		policyholderIsBorrower: isInsurer,
		borrower: contragents[1] ? formatFormPersonDataToBackPerson(contragents[1]) : null,
		// bank step
		credit: {
			city: dealCountry || null,
			insuranceType: Number(insureType) || null,
			bank: getClearSelectValue(creditOrganizationName),
			creditSum: summ || null,
			creditTerms: creditTerms || null,
			creditRate: creditRate || null,
			creditIssueDate: creditIssueDate ? formatDateToStringRu(creditIssueDate) : null,
			creditAgreementNumber: creditAgreementNumber || null,
			dateBegin: dateBegin ? formatDateToStringRu(dateBegin) : null,
			periodTypeChoose,
			dateExactEnd: dateExactEnd ? formatDateToStringRu(dateExactEnd) : null,
			firstPayDate: firstPayDate ? formatDateToStringRu(firstPayDate) : null,
			agent: getClearSelectValue(agent),
			prevSk: prevSk || null,
			renewReason: renewReason || null,
			renewReasonOther: renewReasonOther || null,
			borrowerShare: Number(borrowerShare) || 0,
			birthDateMethod: getClearSelectValue(birthDateMethod),
			marketing: getClearSelectValue(marketing)
		},
		coBorrowers: (coBorrowers || []).reduce<ContragentFragment[]>((prev, next) => (next ? [...prev, formatFormPersonDataToBackPerson(next)] : prev), []),
		// property step
		propertyObjects: properties.reduce<PropertyObjectForm[]>((prev, next) => (next ? [...prev, formatFormPropertyDataToBackProperty(next)] : prev), []),
		// titles step
		titleObjects: titles.reduce<TitleObjectForm[]>((prev, next) => (next ? [...prev, formatFormTitleDataToBackTitle(next)] : prev), []),
	};
};

type SaveAgreementCmd = {
	id: AgreementId;
	currentFormData?: CreateAgreementForm;
	step: AgreementSteps;
	errorCallback: (error: ErrorType) => void;
	successCallback: () => void;
};

type OnlineFillingCmd = {
	id: AgreementId;
	personId?: ContragentId;
	isFull: boolean;
	currentFormData: OnlineFillingForm;
	errorCallback: (error: ErrorType) => void;
	successCallback: (anketaIsn: number) => void;
	fillingType: FillingType;
};

type UploadFillingCmd = {
	id: AgreementId;
	personId?: ContragentId;
	currentFormData: UploadFillingForm;
	errorCallback: (error: ErrorType) => void;
	successCallback: () => void;
	fillingType: FillingType;

	agreementMode: boolean;
};

type SendFillingCmd = {
	id: AgreementId;
	personId?: ContragentId;
	errorCallback: (error: ErrorType) => void;
	successCallback: () => void;
	fillingType: FillingType;
};

const formatAnketaValueToBack = (value: string | number | ISelectItem): string | number | boolean | undefined | null => {
	if (value === 'true') return true;
	if (value === 'false') return false;
	if (typeof value === 'object') return Number(value?.value) || null;
	return value;
};

const uploadFilesToB2b = (api: GQLApi, agreementId: AgreementId, files: File[], successCallback: () => void, errorCallback: (error: ErrorType) => void) => {
	if(!files.length){
		successCallback();
		return;
	}
	const file = files.shift();
	console.log('Send file...', file?.name);
	api
		.mutate<AddAgreementFilesMutation, AddAgreementFilesMutationVariables>({
			variables: {
				agreementId: agreementId || 0,
				files: [ file ],
				context: AgreementFileContextEnum.Agreement,
			},
			mutation: ADD_AGREEMENT_FILE,
		})
		.then(() => {
			uploadFilesToB2b(api, agreementId, files, successCallback, errorCallback);
		})
		.catch(error => errorCallback(error));
};

export const CommandsCreateAgreement = (api: GQLApi, queriesCreateAgreements: QueriesCreateAgreement) => ({

	saveAgreement: ({ id, currentFormData, step, errorCallback, successCallback }: SaveAgreementCmd) => {
		if (!id) return errorCallback('no agreement id');
		queriesCreateAgreements
			.getAgreementById(id)
			.then(({ data }) => {
				if (!data) return errorCallback('no agreement data');
				const mergedFormData = { ...formatAgreementDataToFormData(data), ...(currentFormData || {}) };
				api
					.mutate<SaveAgreementMutation, SaveAgreementMutationVariables>({
						variables: {
							id,
							calcForm: formatFormDataToAgreementData(mergedFormData, step),
						},
						mutation: SAVE_AGREEMENT_DRAFT,
						awaitRefetchQueries: true,
						refetchQueries: [
							{
								query: AGREEMENT_DRAFT,
								variables: {
									id,
								},
							},
						],
					})
					.then(() => {
						if (!(currentFormData?.additionalFiles || []).length) return successCallback();
						uploadFilesToB2b(api, id, currentFormData?.additionalFiles || [], successCallback, errorCallback);
					})
					.catch(error => errorCallback(error));
			})
			.catch(error => errorCallback(error));
	},
	onlineFilling: ({ id, currentFormData, personId, isFull, fillingType, successCallback, errorCallback }: OnlineFillingCmd) => {
		if (!id || !personId) return errorCallback('no agreement id');
		queriesCreateAgreements
			.getAgreementById(id)
			.then(({ data }) => {
				if (!data) return errorCallback('no agreement data');
				const { jobPlace, organizationName, organizationAddress, isOfficeJob, isSoldier, position, field } = currentFormData;
				const agreementFormData = formatAgreementDataToFormData(data);
				const currentFormDataIsFull = {
					...agreementFormData,
					coBorrowers: (currentFormData?.coBorrowers || []).map(coBorrower => {
						if (coBorrower.isn !== personId || !isFull) return coBorrower;
						return {
							...coBorrower,
							jobPlace,
							organizationName,
							organizationAddress,
							isOfficeJob,
							isSoldier,
							position,
							field,
						};
					}),
				};
				debugger;

				const anketaAnswers = Object.entries(currentFormData).reduce<{ isn: string; value: any; remark?: string | number | ISelectItem }[]>((prev, [key]) => {
					if (!key.startsWith('question-') || key.endsWith('-remark')) return prev;
					const isn: string | undefined = key.split('-')[1];
					const remark = currentFormData[`question-${isn}-remark`];
					const fieldValue = currentFormData[`question-${isn}`];
					const formattedValue = formatAnketaValueToBack(fieldValue);
					if (!isn) return prev;
					return [...prev, { isn, remark, value: formattedValue }];
				}, []);
				if (!personId) return errorCallback('no person id');

				api.mutate<SaveAgreementMutation, SaveAgreementMutationVariables>({
					variables: {
						id,
						calcForm: formatFormDataToAgreementData(currentFormDataIsFull, 'insuranceStep'),
					},
					mutation: SAVE_AGREEMENT_DRAFT,
				})
					.then(() => {
						api.mutate<SaveAnketaMutation, SaveAnketaMutationVariables>({
							variables: {
								contragentIsn: personId,
								id,
								questions: anketaAnswers,
							},
							mutation: SAVE_ANKETA,
							awaitRefetchQueries: true,
							refetchQueries: [
								{
									query: AGREEMENT_DRAFT,
									variables: {
										id,
									},
								},
							],
						})
						.then(({ data: anketaData }) => {
							const anketaIsn: number | null = anketaData?.saveAnketa?.anketaIsn || null;
							if (!anketaIsn) return errorCallback('no anketa id');
							successCallback(anketaIsn);
						})
						.catch(error => errorCallback(error));
					})
				.catch(error => errorCallback(error));
			})
			.catch(error => errorCallback(error));
	},
	uploadFilling: ({ id, personId, currentFormData, fillingType, successCallback, errorCallback, agreementMode }: UploadFillingCmd): void => {
		if (!id || !personId) return errorCallback('no agreement id');
		api
			.mutate<AddAgreementFilesMutation, AddAgreementFilesMutationVariables>({
				variables: {
					agreementId: id,
					files: currentFormData.anketaFiles,
					context: AgreementFileContextEnum.Anketa,
				},
				mutation: ADD_AGREEMENT_FILE,
			})
			.then(({ data: dataFiles }) => {
				const anketaFileId: string[] | undefined = dataFiles?.addAgreementFiles ? dataFiles.addAgreementFiles.map(file => file.id) : undefined;

				if(agreementMode){
					api
						.mutate<UpdateContragentFillingMutation, UpdateContragentFillingMutationVariables>({
							variables: {
								id,
								contragentIsn: 0,
								fillingType: ContragentFillingTypeEnum.Declaration
							},
							mutation: UPDATE_CONTRAGENT_FILLING,
							awaitRefetchQueries: true,
							refetchQueries: [
								{
									query: LOAD_CONTRACT,
									variables: {
										id,
									},
								},
							],
						})
						.then(() => successCallback())
						.catch(error => errorCallback(error));
					return;
				}

				queriesCreateAgreements.getAgreementById(id).then(({ data }) => {
					if (!data) return errorCallback('no agreement data');
					const formData = { ...formatAgreementDataToFormData(data) };
					const formDataToSave = {
						...formData,
						contragents: formData.contragents.map(contragent => {
							if (contragent.isn !== personId) return contragent;
							return { ...contragent, anketaFileId, fillingType };
						}),
						coBorrowers: (formData?.coBorrowers || []).map(coBorrower => {
							if (coBorrower.isn !== personId) return coBorrower;
							return { ...coBorrower, anketaFileId, fillingType };
						}),
					};
					api
						.mutate<SaveAgreementMutation, SaveAgreementMutationVariables>({
							variables: {
								id,
								calcForm: formatFormDataToAgreementData(formDataToSave, 'insuranceStep'),
							},
							mutation: SAVE_AGREEMENT_DRAFT,
							awaitRefetchQueries: true,
							refetchQueries: [
								{
									query: AGREEMENT_DRAFT,
									variables: {
										id,
									},
								},
							],
						})
						.then(() => successCallback())
						.catch(error => errorCallback(error));
				});
			})
			.catch(error => errorCallback(error));
	},
	sendFilling: ({ id, personId, fillingType, successCallback, errorCallback }: SendFillingCmd): void => {
		if (!id || !personId) return errorCallback('no agreement id');
		queriesCreateAgreements.getAgreementById(id).then(({ data }) => {
			if (!data) return errorCallback('no agreement data');
			const formData = { ...formatAgreementDataToFormData(data) };
			const formDataToSave = {
				...formData,
				contragents: formData.contragents.map(contragent => {
					if (contragent.isn !== personId) return contragent;
					return { ...contragent, fillingType };
				}),
				coBorrowers: (formData?.coBorrowers || []).map(coBorrower => {
					if (coBorrower.isn !== personId) return coBorrower;
					return { ...coBorrower, fillingType };
				}),
			};
			api
				.mutate<SaveAgreementMutation, SaveAgreementMutationVariables>({
					variables: {
						id,
						calcForm: formatFormDataToAgreementData(formDataToSave, 'insuranceStep'),
					},
					mutation: SAVE_AGREEMENT_DRAFT,
					awaitRefetchQueries: true,
					refetchQueries: [
						{
							query: AGREEMENT_DRAFT,
							variables: {
								id,
							},
						},
					],
				})
				.then(() => successCallback())
				.catch(error => errorCallback(error));
		});
	},
});
