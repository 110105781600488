import { gql } from '@apollo/client';

export const ME = gql`
	query me {
		me {
			user {
				id
				type
				full_name
				username
				email
				phone
				email
				isSuper
				isAdmin
				show_kv
	
				role {
					id
				}
				group {
					id
					curator {
						email
						phone
						username
					}
					is_filial
				}
				permissions
				restrictions {
					tag
					value
				}
				banksOk
				isStaffMember
			}
			adminUser {
				id
				username
			}
		}
	}
`;

export const DASHBOARD = gql`
	query dashboardInfo {
		dashboard {
			agreements {
				count
				status
			}
			contracts {
				count
				status
			}
		}
	}
`;

export const ROLES = gql`
	query roles($ids: [Int]) {
		roles(ids: $ids) {
			id
			name
			permissions {
				id
				name
				tag
			}
			permission_ids
			users_count
		}
	}
`;

export const PERMISSIONS = gql`
	query permissions {
		permissions {
			id
			name
			tag
		}
	}
`;

export const GROUPS = gql`
	fragment Person on User {
		id
		full_name
		username
		email
		phone
	}

	query groups($ids: [Int]) {
		groups(ids: $ids) {
			id
			parent_id
			name
			email
			restrictions {
				tag
				value
			}
			curator {
				...Person
			}
			leader {
				...Person
			}
		}
	}
`;

export const USERS = gql`
	query users($ids: [Int], $roleId: Int) {
		users(ids: $ids, roleId: $roleId) {
			id
			email
			phone
			full_name
			username
			type
			isSuper
			group_id
			is_enabled
			restrictions {
				tag
				value
			}
			show_kv
			role {
				id
				name
			}
			token
			remark
		}
	}
`;

export const DICTI = gql`
	query dict($key: DictKeyEnum!) {
		dict(key: $key) {
			isn
			name
			extra
		}
	}
`;

export const DOCS = gql`
	fragment Person on User {
		id
		full_name
		username
		email
		phone
	}

	query docs($limit: Int, $page: Int, $type: DocTypeEnum, $isn: String, $filter: DocFilterInput) {
		docs(limit: $limit, page: $page, type: $type, isn: $isn, filter: $filter) {
			data {
				id
				isn
				number
				type
				creditSumm
				bankName
				insurerName
				status {
					value
					label
					color
				}
				user {
					...Person
				}
				groupPath
				added
				edited
				editor {
					...Person
				}
				isnInvoice
				needProlongation
				isProlongation
				calcForm {
					stage
				}
				underwriters {
					...Person
				}
				fillingType
			}
			total
		}
	}
`;

export const STATUSES = gql`
	query statuses {
		statuses {
			value
			label
			color
		}
	}
`;

export const PRECALCULATION = gql`
	mutation precalc($precalcForm: PreCalcFormInput) {
		precalc(precalcForm: $precalcForm) {
			isn
			sum
			total
			bank {
				isn
				name
				icon
			}
			risks {
				name
				borrowers {
					need_medical_checkup
					name
					tariff
					premium_sum
				}
			}
			isExpressDeclarationAvailable
			noExpressDeclarationReason
		}
	}
`;

const SELECT_FRAGMENT = gql`
	fragment Select on LabelValueForm {
		label
		value
	}
`;

const ADDRESS_FRAGMENT = gql`
	fragment Address on AddressForm {
		value
		unrestricted_value
		data
	}
`;

const EMPLOYMENT_FRAGMENT = gql`
	${SELECT_FRAGMENT}
	${ADDRESS_FRAGMENT}
	fragment Employment on EmploymentForm {
		jobPlace {
			...Select
		}
		organizationName {
			...Address
		}
		jobAddress {
			...Address
		}
		isOfficeWorker
		isSoldier
		position
		organizationActivity {
			...Select
		}
		riskGroup {
			...Select
		}
		profession {
			...Select
		}
	}
`;

const CONTRAGENT_FRAGMENT = gql`
	${SELECT_FRAGMENT}
	${ADDRESS_FRAGMENT}
	${EMPLOYMENT_FRAGMENT}

	fragment Contragent on ContragentForm {
		isn
		fio
		birthday
		phone
		email
		sex
		registrationAddress {
			...Address
		}
		actualAddress {
			...Address
		}
		registrationAddressIsActual
		passportCountry {
			...Select
		}
		passportSeries
		passportNumber
		passportIssueDate
		passportDevisionCode
		passportIssuedBy
		share
		employment {
			...Employment
		}
		anketaIsn
		fillingType
		anketaSigned
		anketaFileId
		anketas {
			isn
			id
			date
			result
			remark
		}
	}
`;

export const AGREEMENT_DRAFT = gql`
	${SELECT_FRAGMENT}
	${ADDRESS_FRAGMENT}
	${CONTRAGENT_FRAGMENT}

	query loadAgreement($id: Int) {
		loadAgreement(id: $id) {
			id
			steps
			calcForm {
				stage
				riskLife
				riskProperty
				riskTitle
				policyholder {
					...Contragent
				}
				policyholderIsBorrower
				borrower {
					...Contragent
				}
				credit {
					insuranceType
					city {
						...Address
					}
					bank {
						...Select
					}
					creditSum
					creditTerms
					creditRate
					creditIssueDate
					creditAgreementNumber
					dateBegin
					dateExactEnd
					periodTypeChoose
					firstPayDate
					salesChannel {
						...Select
					}
					agent {
						...Select
					}
					prevSk {
					    ...Select
					}
					renewReason {
					    ...Select
					}
					renewReasonOther
					borrowerShare
					birthDateMethod {
						...Select
					}
					marketing {
					 	...Select
					} 	
				}
				coBorrowers {
					...Contragent
				}
				propertyObjects {
					propertyPledge {
						...Select
					}
					propertyPledgeAddress {
						...Address
					}
					pledgeCadastralNumber
					pledgeAddressEgrn
					propertySumInsured
					pledgeShare
					objectCost
					objectCostByContract
					agency
					nameOfAppraiser
					area
					roomsCount
					floor
					floorCount
					buildYear
					yearOfCapitalRepair
					wallMaterial {
						...Select
					}
					floorMaterial {
						...Select
					}
					signaling
					signalingInfo
					openFire
					openFireInfo
					additionalRisks
					additionalRisksInfo
					objectOfUnfinishedConstruction
					repairPlanning
					repairIntervalFrom
					repairIntervalTo
					riskCoefficient
					basementOrAttic
					interiorDecoration
					replanPlanning
					replanIntervalFrom
					replanIntervalTo
					rent
					damage
				}
				titleObjects {
					titleAddress {
						...Address
					}
					titleCadastralNumber
					titleShare
					objectEncumbrances
					minors
					unregisterPersons
					litigation
					addressEgrn
					typeEncumbrances
				}
			}
			declarationAvailable
			createAgreementAvailable
			agreementDeclarationDone
			isExpressMode
			isAddendumMode
			files {
				id
				filename
				url
			}
			isn
		}
	}
`;

export const LOAD_AGREEMENT = gql`
	query loadAgreementUnderwriting($id: Int) {
		loadAgreement(id: $id) {
			id
			number
			added
			relatedAgreementId
			status {
				label
				value
				color
			}
			user {
				id
				isSuper
				username
				group {
					id
					name
				}
			}
			calcForm {
				riskLife
				riskProperty
				riskTitle
			}
			calculation {
				policyholder_fio
				policyholder_birthday
				policyholder_email
				beneficiary
				credit_terms
				premium_sum
				premium_sum_with_kv
				discount_kv
				seller_discount_due_kv
				discount_kv_sum
				agent
                bank_kv
				tariff_group {
					label
					value
				}
				program {
					label
					value
				}
				life_risks {
					isn
					fio
					birthday
					type
					address
					property_cost
					status
					limit_sum
					base_tariff
					base_premium_sum
					raising_factor
					discount
					tariff
					premium_sum
					high_tariff_height
					high_tariff_press
					share
					remark
					autoscoring
					underwriting_allowed
				}
				property_risks {
					isn
					fio
					birthday
					type
					address
					property_cost
					status
					limit_sum
					base_tariff
					base_premium_sum
					raising_factor
					discount
					tariff
					premium_sum
					high_tariff_height
					high_tariff_press
					share
					autoscoring
					underwriting_allowed
				}
				titul_risks {
					isn
					fio
					birthday
					type
					address
					property_cost
					status
					limit_sum
					base_tariff
					base_premium_sum
					raising_factor
					discount
					tariff
					premium_sum
					high_tariff_height
					high_tariff_press
					share
					autoscoring
					underwriting_allowed
				}
			}
			resultDocuments {
				name
				type
				isn
				templateIsn
				subject
				warning
			}
			underwriters {
				id
				username
			}
			fillingTypeText
		}
	}
`;
export const LOAD_CONTRACT = gql`
	${CONTRAGENT_FRAGMENT}

	query loadContract($id: Int) {
		loadAgreement(id: $id) {
			id
			number
			isn
			added
			relatedCalculationId
			status {
				label
				value
				color
			}
			user {
				id
				isSuper
				username
				group {
					id
					name
				}
			}
			calculation {
				premium_sum
				premium_sum_with_kv
				discount_kv
			}
			agreement {
				premium_sum
				premium_sum_with_kv
				discount_kv
				seller_discount_due_kv
				agreement_no
				credit_months
				credit_rate
				currency
				date_sign
				date_begin
				date_end
				policyholder {
					...Contragent
				}
				ins_purpose {
					label
					value
				}
				credit_bank_text
				credit_bank_isn
				credit_agreement_number
				credit_agreement_date
				credit_sum
				credit_annuitet_sum
				ins_previous {
					label
					value
				}
				sale_channel {
					label
					value
				}
				agr_role {
					role
					participant
					date_beg
					date_end
				}
				life_risks {
					isn
					fio
					birthday
					type
					address
					property_cost
					status
					limit_sum
					base_tariff
					base_premium_sum
					raising_factor
					discount
					tariff
					premium_sum
					high_tariff_height
					high_tariff_press
					share
					remark
					autoscoring
					underwriting_allowed
				}
				property_risks {
					isn
					fio
					birthday
					type
					address
					property_cost
					status
					limit_sum
					base_tariff
					base_premium_sum
					raising_factor
					discount
					tariff
					premium_sum
					high_tariff_height
					high_tariff_press
					share
					remark
					permanent_sum
					one_time_pay
					autoscoring
					underwriting_allowed
				}
				titul_risks {
					isn
					fio
					birthday
					type
					address
					property_cost
					status
					limit_sum
					base_tariff
					base_premium_sum
					raising_factor
					discount
					tariff
					premium_sum
					high_tariff_height
					high_tariff_press
					share
					remark
					permanent_sum
					one_time_pay
					date_end
					autoscoring
					underwriting_allowed
				}
				is_payed
				has_cross_sale
				is_declaration
				agent_kv_sum
			}
			invoices {
				invoiceIsn
				invoiceNo
				invoiceSum
				summName
				invoiceCaption
				agreementIsn
				agreementNo
				isAlreadyPayed
				types {
					isn
					name
				}
			}
			resultDocuments {
				name
				isn
				type
				templateIsn
				subject
				warning
			}
			readyToSign
			needProlongation
			nextInvoiceAllowed
			isCurrentPeriodPayed
			nextInvoiceSum
			isProlongation
			prevAgreementIsn
			prevAgreementId
			nextAgreementIsn
			agreementDeclarationDone
			isExpressMode
			prevAgreementAnketaIsn
			agreementAnketaIsn
			agreementAnketaEdit
		}
	}
`;

export const SEARCH_CONTRAGENT = gql`
	${CONTRAGENT_FRAGMENT}

	query searchContragent($id: Int, $fullname: String, $birthday: String, $phone: String, $isn: String) {
		searchContragent(id: $id, fullname: $fullname, birthday: $birthday, phone: $phone, isn: $isn) {
			...Contragent
		}
	}
`;

export const LOAD_MESSAGES = gql`
	query loadMessages($id: Int) {
		loadAgreement(id: $id) {
			id
			comments {
				id
				text
				date
				user {
					id
					full_name
				}
				isNew
			}
		}
	}
`;

export const LOAD_CHANGE_LOG = gql`
	query loadChangeLog($id: Int) {
		loadAgreement(id: $id) {
			id
			activity_log {
				added
				user {
					id
					username
				}
				adminUser {
					id
					username
				}
				event
				eventName
				description
			}
		}
	}
`;

export const LOAD_FILES = gql`
	query loadFiles($id: Int) {
		loadAgreement(id: $id) {
			id
			calcForm {
				riskLife
				riskProperty
				riskTitle
			}
			files {
				id
				filename
				url
			}
		}
	}
`;

export const LOAD_ANKETA = gql`
	query loadAnketa($id: Int) {
		loadAgreement(id: $id) {
			id
			anketa {
				name
				questions {
					question
					answer
				}
			}
		}
	}
`;

export const ANKETA = gql`
	query anketa($id: Int!, $contragentIsn: Int!) {
		anketa(id: $id, contragentIsn: $contragentIsn) {
			key
			text
			questions {
				isn
				type
				text
				dicti {
					isn
					name
				}
				value
				remark
			}
		}
	}
`;

export const USER_GROUP_HISTORY = gql`
query changeLog($model: ChangeLogTableEnum, $id: Int!) {
  changeLog(model: $model, id: $id) {
    createdAt
    user {
      id
      name
    }
    fields {
      field
      label
      oldValue
      newValue
    }
    comment
  }
}
`;

export const GET_ADMIN_MESSAGE = gql`
	query adminMessage {
		adminMessage 
	}
`;

export const MESSAGES = gql`
query messages {
  messages {
    id
    text
  }
}
`;
