import React, {createContext, useContext} from 'react';
import {useFieldArray, useFormContext} from 'react-hook-form';
import dayjs from 'dayjs';
import {IForm} from '../../../../../../../libs/uiKit/fieldsBuilder/types';
import useGetDicti from '../../../../../common/hooks/useGetDicti';
import {ContragentFragment, DictKeyEnum} from '../../../../../../../libs/api/graphqlTypes';
import {ContragentContextProps, ContragentField} from '../../../../widgets/contragent/ui/context';
import {ISelectItem} from '../../../../../../../libs/uiKit/select/types';
import {useGlobalContext} from '../../../../../../../apps/main/ui/GlobalContext';
import {RADIO_BOOLEAN, RADIO_PERIOD_TYPE, SBERBANK_ISN} from '../../../../../../../libs/utils/staticData';
import {DEFAULT_CONTRAGENT_DATA, formatPersonDataToDataForm} from '../../../../ds/repositories/commands';
import {useCreateAgreementContext} from '../../../../ui/context';
import {IDaDataValue} from '../../../../../../../libs/uiKit/daData/types';
import {useUserRights} from "../../../../../common/hooks/useUserRights";

type BankContext = {
	form: IForm;
	isHaveCoBorrowerForm: IForm;
	coBorrowers: ContragentContextProps[];
	addCoBorrowerHandler: () => void;
	deleteCoBorrowerHandler: (index: number) => void;
	isDisabledAddCoBorrower: boolean;
	isShowCoBorrowers: boolean;
};

export type BankForm = {
	coBorrowers?: ContragentField[];
	insureType?: string;
	isHaveCoBorrower?: string;
	creditOrganizationName?: ISelectItem | null;
	summ?: number;
	creditTerms?: number;
	creditRate?: number;
	creditIssueDate?: Date | null;
	creditAgreementNumber?: string;
	dateBegin?: Date | null;
	firstPayDate?: Date | null;
	agent?: ISelectItem | null;
	prevSk?: ISelectItem | null;
	renewReason?: ISelectItem | null;
	renewReasonOther?: string;
	borrowerShare?: number;
	dealCountry?: IDaDataValue | null;
	birthDateMethod?: ISelectItem | null;
	marketing?: ISelectItem | null;
	periodTypeChoose?: string;
	dateExactEnd?: Date | null;
};

const today: Date = dayjs().toDate();

const BankContext = createContext<BankContext>({} as BankContext);

export const useBankContext = (): BankContext => useContext(BankContext);

const BankContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
	const {
		user: {
			restrictions: { contragentMaxAge },
			isSuper,
			isStaffMember
		},
	} = useGlobalContext();
	const formHook = useFormContext<BankForm>();
	const {
		risks: { riskLife },
		isFieldsDisabled
	} = useCreateAgreementContext();
	const { data: selectOrganization, loading: loadingOrganization } = useGetDicti(DictKeyEnum.CreditBank);
	const { data: selectAgreementTypes, loading: loadingAgreementTypes } = useGetDicti(DictKeyEnum.AgreementTypes);
	const { data: agentlist, loading: loadingAgentlist } = useGetDicti(DictKeyEnum.Agentlist);
	const { data: renewReasons, loading: loadingRenewReasons } = useGetDicti(DictKeyEnum.RenewReasons);
	const { data: marketingItems, loading: loadingMarketingItems } = useGetDicti(DictKeyEnum.Marketing);
	const { data: prevSkList, loading: loadingPrevSkList } = useGetDicti(DictKeyEnum.PrevSk);

	const { fields, append, remove, update } = useFieldArray({
		control: formHook.control,
		name: 'coBorrowers',
	});

	const [isShowCoBorrowersValue, creditOrganizationNameValue, creditIssueDate, creditTerms, firstPayDate, renewReason, periodTypeChoose]
		= formHook.watch(['isHaveCoBorrower', 'creditOrganizationName', 'creditIssueDate', 'creditTerms', 'firstPayDate', 'renewReason', 'periodTypeChoose']);
	const isShowCoBorrowers: boolean = isShowCoBorrowersValue === 'true';
	const isSberbank: boolean = Number(creditOrganizationNameValue?.value) === SBERBANK_ISN;

	const [insureTypeValue] = formHook.watch(['insureType']);
	const isChangeInsuraceCase = insureTypeValue === '4';
	const isRenewCase = insureTypeValue === '5';
	const isShowRenewReasonOther = isRenewCase && renewReason?.value === '5';

	const isAdditionalInsuranceCase = insureTypeValue === '2';
	console.log('insureTypeValue', insureTypeValue);

	const {rightsHandler} = useUserRights();

	const addCoBorrowerHandler = React.useCallback(() => append(DEFAULT_CONTRAGENT_DATA), [append]);
	const deleteCoBorrowerHandler = React.useCallback((index: number) => remove(index), [remove]);

	const searchSuccessCallback = React.useCallback(
		(contragent: ContragentFragment, number: number) => {
			update(number, formatPersonDataToDataForm(contragent));
		},
		[update]
	);

	React.useEffect(() => {
		console.log('useEffect');
		if(!periodTypeChoose || isSberbank && periodTypeChoose !== RADIO_PERIOD_TYPE[1].value){
			formHook.setValue('periodTypeChoose', RADIO_PERIOD_TYPE[1].value);
			console.log('setPeriodTypeChoose');
		}
		if (!isSberbank) return;
		formHook.setValue('creditTerms', 12);
		if(!creditIssueDate){
			formHook.setValue('creditIssueDate', today);
		}
		if(!firstPayDate){
			formHook.setValue('firstPayDate', today);
		}
	}, [isSberbank, periodTypeChoose]);

	const form: IForm = React.useMemo(
		() => ({
			formHook,
			hotReload: true,
			isDisabled: isFieldsDisabled || undefined,
			fields: [
				{
					label: 'Вид договора',
					field: {
						fieldType: 'radio',
						template: '1fr 1fr',
						fieldName: 'insureType',
						isLoading: loadingAgreementTypes,
						items: selectAgreementTypes,
					},
				},
				{
					field: {
						fieldType: 'daData',
						daDataType: 'address',
						fieldName: 'dealCountry',
						placeholder: 'Населенный пункт сделки',
						validationType: 'addressWithCity',
						isRequired: true,
						grid: 6,
					},
				},
				{
					field: {
						fieldType: 'select',
						fieldName: 'creditOrganizationName',
						isRequired: true,
						grid: 6,
						isLoading: loadingOrganization,
						errorMessage: 'Выберите наименование',
						placeholder: 'Наименование кредитной организации',
						isSearchAvailable: true,
						items: selectOrganization,
					},
				},
				{
					field: {
						fieldType: 'number',
						fieldName: 'summ',
						isRequired: true,
						grid: 4,
						digitsAfterDot: 2,
						errorMessage: 'Введите размер кредита',
						placeholder: 'Размер кредита',
						validationType: 'minMax',
						maxValue: 1000000000,
					},
				},

				{
					field: {
						fieldType: 'date',
						fieldName: 'creditIssueDate',
						isRequired: true,
						errorMessage: 'Выберите дату',
						placeholder: 'Дата кредитного договора',
						grid: 4,
					},
				},

				{
					field: {
						fieldType: 'date',
						fieldName: 'dateBegin',
						errorMessage: 'Введите дату',
						placeholder: 'Начало действия полиса',
						validationType: 'minMaxDate',
						minDate: dayjs().toDate(),
						grid: 4,
					},
				},

				{
					label: '',
					field: {
						fieldType: 'radio',
						fieldName: 'periodTypeChoose',
						items: RADIO_PERIOD_TYPE,
						grid: 8,
						isDisabled: true,
					},
				},

				{
					isHidden: periodTypeChoose !== 'month',
					field: {
						fieldType: 'number',
						fieldName: 'creditTerms',
						isRequired: true,
						isDisabled: isSberbank,
						validationType: 'minMax',
						maxValue: 600,
						errorMessage: 'Введите срок кредитования',
						placeholder: 'Срок кредитования (месяцы) / оставшийся срок кредитования',
						grid: 4,
					},
				},

				{
					isHidden: periodTypeChoose !== 'date',
					field: {
						fieldType: 'date',
						fieldName: 'dateExactEnd',
						errorMessage: 'Введите дату',
						placeholder: 'Дата окончания',
						validationType: 'minMaxDate',
						isRequired: true,
						minDate: dayjs().toDate(),
						grid: 4,
					},
				},

				{
					isHidden: isSberbank,
					field: {
						fieldType: 'number',
						fieldName: 'creditRate',
						digitsAfterDot: 2,
						isRequired: true,
						errorMessage: 'Введите ставку',
						placeholder: 'Процентная ставка по кредиту, %',
						validationType: 'minMax',
						maxValue: 100,
						grid: 4,
					},
				},

				{
					field: {
						fieldType: 'input',
						fieldName: 'creditAgreementNumber',
						errorMessage: 'Введите номер',
						placeholder: 'Номер кредитного договора',
						grid: 4,
					},
				},

				{
					isHidden: !riskLife || !rightsHandler('unifiedRatesCalculation'),
					field: {
						fieldType: 'select',
						fieldName: `birthDateMethod`,
						placeholder: `Расчет возраста застрахованного`,
						//defaultValue: {label: "По году рождения", value: "0"},
						items: [
							{label: "По году рождения", value: "0"},
							{label: "По дате рождения", value: "1"},
						],
						grid: 4
					},
				},

				/* {
					field: {
						fieldType: 'date',
						fieldName: 'firstPayDate',
						isDisabled: isSberbank,
						placeholder: 'Дата первого платежа (дата списания аннуитета)',
					},
				}, */
				// скрываем вообще, Хорев 17.08

				/* {
					isHidden: !isSuper,
					field: {
						fieldType: 'select',
						fieldName: 'salesChannel',
						isRequired: true,
						defaultValue: selectSalesChannel[0],
						isLoading: loadingSalesChannel,
						errorMessage: 'Выберите канал продаж',
						placeholder: 'Канал продаж',
						items: selectSalesChannel,
					},
				}, */
				// с платформы всегда "Банки", а если интеграторы, то у них канал сам встает от настройки, Хорев 15.09
				{
					isHidden: !isChangeInsuraceCase,
					field: {
						fieldType: 'select',
						fieldName: 'prevSk',
						// defaultValue: getSelectDefaultValue(agent, selectAgents),
						isLoading: loadingPrevSkList,
						placeholder: 'Предыдущая страховая компания',
						items: prevSkList,
						isRequired: true,
						isSearchAvailable: true,
						isClearable: true,
					},
				},
				{
					isHidden: !isRenewCase,
					field: {
						fieldType: 'select',
						fieldName: 'renewReason',
						isLoading: loadingRenewReasons,
						placeholder: 'Причина возобновления',
						items: renewReasons,
						isClearable: true,
						isRequired: true,
					},
				},
				{
					isHidden: !isRenewCase || !isShowRenewReasonOther,
					field: {
						fieldType: 'input',
						fieldName: 'renewReasonOther',
						placeholder: 'Укажите причину возобновления',
						isRequired: true,
					},
				},
				{
					isHidden: !isStaffMember || !agentlist,
					field: {
						fieldType: 'select',
						fieldName: 'agent',
						// defaultValue: getSelectDefaultValue(agent, selectAgents),
						isLoading: loadingAgentlist,
						placeholder: 'Добавить агента',
						items: agentlist,
						isSearchAvailable: true,
						isClearable: true,
					},
				},
				{
					isHidden: !riskLife || !isShowCoBorrowers,
					field: {
						fieldType: 'range',
						fieldName: 'borrowerShare',
						isRequired: true,
						errorMessage: 'Выберите долю заемщика',
						placeholder: 'Доля заемщика',
						min: 0,
						max: 100,
						step: 1,
					},
				},
				{
					isHidden: !rightsHandler('marketingField'),
					field: {
						fieldType: 'select',
						fieldName: 'marketing',
						isLoading: loadingMarketingItems,
						placeholder: 'Маркетинговая акция',
						items: marketingItems,
						isSearchAvailable: true,
						isClearable: true,
					},
				},
			],
		}),
		[formHook, isSberbank, isSuper, loadingAgreementTypes, loadingOrganization, riskLife, selectAgreementTypes, selectOrganization]
	);

	const isHaveCoBorrowerForm: IForm = React.useMemo(
		() => ({
			formHook,
			isDisabled: isFieldsDisabled,
			fields: [
				{
					isHidden: !riskLife,
					label: 'Есть ли созаемщики по кредиту?',
					field: {
						fieldType: 'radio',
						fieldName: 'isHaveCoBorrower',
						items: RADIO_BOOLEAN,
					},
				},
			],
		}),
		[formHook, riskLife]
	);

	const value: BankContext = React.useMemo(
		() => ({
			form,
			isHaveCoBorrowerForm,
			coBorrowers: fields.map((field, index) => ({
				isShowShare: true,
				isShowSearchByIsn: false,
				arrayName: 'coBorrowers',
				defaultValues: field,
				number: index,
				searchSuccessCallback,
				maxAge: contragentMaxAge,
				contragentDisabled: isFieldsDisabled,
				postfix: "созаемщика"
			})),
			addCoBorrowerHandler,
			deleteCoBorrowerHandler,
			isDisabledAddCoBorrower: fields.length === 5,
			isShowCoBorrowers,
		}),
		[addCoBorrowerHandler, contragentMaxAge, deleteCoBorrowerHandler, fields, form, isHaveCoBorrowerForm, isShowCoBorrowers, searchSuccessCallback]
	);

	return <BankContext.Provider value={value}>{children}</BankContext.Provider>;
};

export default React.memo(BankContextProvider);
