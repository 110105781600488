import { ITabItem } from '../uiKit/tabs/types';
import { IRadioItem } from '../uiKit/radio/types';
import { ISelectItem } from '../uiKit/select/types';

export const PROPERTY_TYPE: ITabItem[] = [
	{ label: 'Строящееся жилье', value: '0' },
	{ label: 'Готовое жилье', value: '1' },
];

export const RADIO_BOOLEAN: IRadioItem[] = [
	{ label: 'Да', value: 'true' },
	{ label: 'Нет', value: 'false' },
];

export const RADIO_PERIOD_TYPE: IRadioItem[] = [
	{ label: 'Точная дата окончания кредита', value: 'date' },
	{ label: 'Срок кредита/оставшийся срок кредита (в месяцах)', value: 'month' },
];

export const RADIO_SEX: IRadioItem[] = [
	{ label: 'Мужской', value: 'M' },
	{ label: 'Женский', value: 'F' },
];

export const RISKS_SELECT: ISelectItem[] = [
	{ label: 'Жизнь', value: 'life' },
	{ label: 'Имущество', value: 'property' },
	{ label: 'Титул', value: 'title' },
];

export const RADIO_TITLE: IRadioItem[] = [
	{ label: 'Менее 3-х лет', value: '3' },
	{ label: 'Более 3-х лет', value: '5' },
];

export const DEFAULT_INSURANCE_TYPE: number = 1; // { value: '1', label: 'Новый кредит' };

export const SBERBANK_ISN: number = 950291;
export const RUSSIA_COUNTRY_ISN: string = '9602';
export const NEED_AUTH_ERROR: string = 'Требуется авторизация';
export const DRAFT_STATUS_VALUE: string = 'draft';

export type Risks = {
	riskLife: boolean;
	riskTitle: boolean;
	riskProperty: boolean;
};
export const DEFAULT_RISKS: Risks = {
	riskLife: true,
	riskTitle: false,
	riskProperty: false,
};

export const PAGES_WITHOUT_BACKGROUND = ['roles', 'agreementsLog', 'contractsLog', 'myAgreements', 'myAnketa', 'prolongationLog'];

export const BASE_URL = process.env.REACT_APP_PUBLIC_URL;

export const ISN_HOSPITAL: string = 'question-835291';
export const ISN_SUGAR: string = 'question-835121';
export const ISN_DISEASES: string[] = [
	'question-835011',
	'question-835021',
	'question-835031',
	'question-835041',
	'question-835051',
	'question-835071',
	'question-835081',
	'question-835091',
	'question-953281',
	'question-835111',
	'question-835131',
	'question-835141',
	'question-835151',
	'question-835161',
	'question-835171',
	'question-835181',
];

export const RADIO_UNDERWRITING: IRadioItem[] = [
	{ label: 'Согласовать', value: 'AGREED' },
	{ label: 'Отказать', value: 'NOT_AGREED' },
	{ label: 'На согласовании', value: 'UNDERWRITING' },
];

export const ISN_POLICE: string = 'question-834991';

export const ISN_WEIGHT: string = 'question-834851';
export const HIDDEN_WEIGHT: string[] = ['question-857201'];
export const ISN_SMOKE: string = 'question-834891';
export const HIDDEN_SMOKE: string[] = ['question-834901', 'question-834911'];
export const ISN_ALCOHOL: string = 'question-834921';
export const HIDDEN_ALCOHOL: string[] = ['question-834931', 'question-834941', 'question-834951', 'question-834961'];
export const ISN_SPORT: string = 'question-835371';
export const HIDDEN_SPORT: string[] = ['question-857211'];
export const ISN_EXTREME: string = 'question-857231';
export const HIDDEN_EXTREME: string[] = ['question-857351'];
export const REQUIRED_ANKETA_FIELDS = [
	'question-834831',
	'question-834841',
	'question-834881',
	'question-834871',
	'question-834961',
];
